import React from 'react'
import Header from './header'
import Footer from './footer'
import '../assets/scss/main.scss'

export default ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer />
  </>
)
