import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import NavLink from './nav-link'

const Header = () => {
  const [open, setOpen] = useState(false)
  const toggleClass = () => {
    setOpen(!open)
  }
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      logoSVG: file(relativePath: { eq: "general/logo.svg" }) {
        name
        publicURL
      }
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  const { logoSVG, site } = data
  const menuList = site.siteMetadata.menuLinks

  return (
    <header className="header bg-green py-3">
      <div className="container">
        <div className="d-flex flex-column flex-lg-row justify-content-between flex-fill">
          <div className="d-flex flex-row justify-content-between">
            <Link
              className="mr-4 mr-sm-0"
              title={data.site.siteMetadata.title}
              to="/"
            >
              <img
                src={logoSVG.publicURL}
                alt={logoSVG.name}
                className="img-fluid"
                width="300"
              />
            </Link>
            <button
              className="nav-toggler material-icons my-auto"
              type="button"
              onClick={toggleClass}
            >
              menu
            </button>
          </div>
          <nav
            className={`header-nav mt-4 my-lg-auto ml-lg-auto overflow-hidden navbar-collapse${
              open ? ' navbar-collapse-active' : ''
            }`}
            id="navbarNav"
          >
            <ul className="nav flex-column flex-lg-row w-100 w-lg-auto">
              {menuList.map(cat => (
                <li className="nav-item" key={cat.link}>
                  <NavLink
                    to={cat.link}
                    className={`${
                      cat.link === '/covid-19/' ? ` bg-warning rounded` : ''
                    }`}
                  >
                    {cat.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}
export default Header
