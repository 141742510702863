import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from 'prop-types'

const activeStyle = {
  background: '#38a67c',
}

const NavLink = ({ children, to, className }) => (
  <AnchorLink
    to={to}
    title={children}
    className={`nav-link text-uppercase text-white font-weight-bold${className}`}
    activeStyle={activeStyle}
  >
    {children}
  </AnchorLink>
)

NavLink.propTypes = {
  className: PropTypes.string,
}

NavLink.defaultProps = {
  className: '',
}

export default NavLink
