import React, { Component } from 'react'
import posed, { PoseGroup } from 'react-pose'

const ModalContent = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
})

const Shade = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

class Modal extends Component {
  componentWillUnmount() {
    const $body = document.body
    $body.className =
      $body.classList.contains('modal-open') &&
      $body.className.replace(/\bmodal-open\b/g, '')
  }

  render() {
    const { theme, showModal, children, className } = this.props
    return (
      <PoseGroup>
        {showModal && [
          // If animating more than one child, each needs a `key`
          <Shade key="shade" className="shade" />,
          <ModalContent key="modal" className={`modal-container ${className}`}>
            <div
              className={`modal-content${
                theme === `green` ? ` bg-green` : ` bg-black`
              }`}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close material-icons"
                  onClick={this.props.handleClose}
                >
                  close
                </button>
                <h5 className="modal-title m-0">{this.props.title}</h5>
              </div>
              <div
                className={`modal-body${
                  theme === `green` ? ` bg-green` : ` bg-black`
                }`}
              >
                {children}
              </div>
            </div>
          </ModalContent>,
        ]}
      </PoseGroup>
    )
  }
}
export default Modal
