import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import NavLink from './nav-link'
import Modal from './modal'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      logoSVG: file(relativePath: { eq: "general/logo.svg" }) {
        name
        publicURL
      }
      twitterSVG: file(relativePath: { eq: "general/twitter.svg" }) {
        name
        publicURL
      }
      facebookSVG: file(relativePath: { eq: "general/facebook.svg" }) {
        name
        publicURL
      }
      instagramSVG: file(relativePath: { eq: "general/instagram.svg" }) {
        name
        publicURL
      }
      linkedinSVG: file(relativePath: { eq: "general/linkedin.svg" }) {
        name
        publicURL
      }
      youtubeSVG: file(relativePath: { eq: "general/youtube.svg" }) {
        name
        publicURL
      }
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)
  const [statusText, setStatusText] = useState('')
  const [visibleModal, setVisibleModal] = useState(false)
  const [form, setForm] = useState({
    listFields: {
      EMAIL: '',
      PATHNAME: 'Footer section',
      STATUS: '',
    }
  })
  
  const handleModal = isVisible => {
    document.body.className = isVisible ? 'modal-open' : ''
    setVisibleModal(isVisible)
  }
  const handleSubmit = async event => {
    event.preventDefault()
    const {listFields} = form
    const result = await addToMailchimp(listFields.EMAIL, listFields)
    setForm({
      "listFields":{
        STATUS: result.msg
      }
    })
  }
  const onChange = e => {
    setForm({
      "listFields":{
      [e.target.name]: e.target.value 
    }})
  }

  const {
    logoSVG,
    twitterSVG,
    facebookSVG,
    instagramSVG,
    linkedinSVG,
    youtubeSVG,
    site,
  } = data
  const menuList = site.siteMetadata.menuLinks

  return (
    <footer className="bg-green pt-5 pb-3 text-white text-center text-lg-left">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <Link className="navbar-brand p-0" to="/">
              <img
                src={logoSVG.publicURL}
                alt={logoSVG.name}
                className="img-fluid"
                width="280"
              />
            </Link>
            <p className="mt-4">Get the latest Energi Impact news</p>
            <div className="d-flex flex-column">
              {!form.listFields.STATUS?(<form
                onSubmit={handleSubmit}
                className="d-flex flex-row mx-auto mx-lg-0"
              >
                <div className="form-group mb-0">
                  <input
                    type="email"
                    name="EMAIL"
                    value={form.listFields.EMAIL}
                    onChange={onChange}
                    className="form-control rounded-0 border-0"
                    placeholder="your email"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-sm btn-dark">
                  Join
                </button>
              </form>):(
          <div className="border border-white p-2 rounded">
            <h6
              className="text-white mb-0"
              dangerouslySetInnerHTML={{ __html: form.listFields.STATUS }}
            />
          </div>
        )}
            </div>
            <div className="d-flex flex-row mt-2">
              <a
                href="https://www.instagram.com/energiimpact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagramSVG.publicURL}
                  alt={instagramSVG.name}
                  loading="lazy"
                  width="40"
                />
              </a>
              <a
                href="https://www.facebook.com/energiimpact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebookSVG.publicURL}
                  alt={facebookSVG.name}
                  loading="lazy"
                  width="40"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCzjSHOp1aT4JwSIPA5w9TXg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={youtubeSVG.publicURL}
                  alt={youtubeSVG.name}
                  loading="lazy"
                  width="40"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/energi-core"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={linkedinSVG.publicURL}
                  alt={linkedinSVG.name}
                  loading="lazy"
                  width="40"
                />
              </a>
              <a
                href="https://twitter.com/energiimpact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitterSVG.publicURL}
                  alt={twitterSVG.name}
                  loading="lazy"
                  width="40"
                />
              </a>
            </div>
          </div>
          <div className="col-lg mt-3 mt-lg-0">
            <ul className="nav justify-content-center justify-content-lg-start mb-4">
              {menuList.map(cat => (
                <li key={cat.link} className="nav-item">
                  <NavLink
                    to={cat.link}
                    className={`${
                      cat.link === '/covid-19/' ? ` bg-warning rounded` : ''
                    }`}
                  >
                    {cat.name}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="p-0 pl-lg-2">
              <h6 className="font-weight-bold">SUPPORT ENERGI IMPACT</h6>
              <p className="mb-1">
                We are a self-funding charity organisation.
              </p>
              <p className="">
                We do not require donations to keep creating impact, however
                your contributions mean we can both help to make the world a
                better place.
              </p>
              <p className="m-0">Donate funds or take action below.</p>
              <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-center align-items-center justify-content-lg-start mt-4">
                <Link
                  to="/make-your-impact/#donation-section"
                  state={{ actionForm: 'donation-form' }}
                  className="btn btn-sm btn-outline-warning mt-3"
                >
                  DONATE
                </Link>
                <Link
                  to="/make-your-impact/#donation-section"
                  className="btn btn-sm btn-outline-warning mt-3 ml-sm-3"
                >
                  TAKE ACTION
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-4 mb-1" />
        <div className="d-flex felx-wrap flex-column flex-lg-row justify-content-lg-between">
          <p className="small text-center mt-3 mb-0">
            © {new Date().getFullYear()} Copyright Energi Core Ltd.
            <br />
            All rights reserved
          </p>
          <ul className="nav justify-content-center mt-3 small">
            <li className="nav-item nav-item-line">
              <Link className="nav-link text-white" to="/terms-of-service">
                Terms of Service
              </Link>
            </li>
            <li className="nav-item nav-item-line">
              <Link className="nav-link text-white" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Modal
        showModal={visibleModal}
        handleClose={() => handleModal(false)}
        title="Get the latest Energi Impact news"
        theme="green"
      >
        <h6 className="text-white py-3 font-weight-bold">{statusText}</h6>
        <div className="d-flex flex-row justify-content-end">
          <button
            className="btn btn-sm btn-outline-warning px-sm-5"
            onClick={() => handleModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </footer>
  )
}

export default Footer
